import { useState, useEffect } from 'react'
import { APIService, LocationService } from '../../services'
import styles from './trip.module.scss'

import BerlinCodes from '../../assets/BerlinZipCodes.json'
import { NUTS } from '../../assets/NUTS3'

import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import SyncRoundedIcon from '@material-ui/icons/SyncRounded';

import { Loader } from '../Loader';
import { Legend } from '../Legend';
import { ResultBox } from '../ResultBox'
import { PartnerInfo } from '../PartnerInfo';
import { TourismInfo } from '../TourismInfo';


export const Trip = () => {

  const tripID = window.location.pathname.split('/')[1]

  const [loading, setLoading] = useState<boolean>(true)
  const [errors, setErrors] = useState<string[]>([])

  const [filteredFrom, setFilteredFrom] = useState<any>([])
  const [filteredTo, setFilteredTo] = useState<any>([])

  const [from, setFrom] = useState<any>(null)
  const [to, setTo] = useState<any>(null)

  const [infoFrom, setInfoFrom] = useState<string[] | null>(null)
  const [infoTo, setInfoTo] = useState<string[] | null>(null)

  const [isTrip, setIsTrip] = useState<boolean>(false)

  const zipData = NUTS

  const getTripData = async () => {
    setLoading(true)

    const res = await APIService.getTripByID(tripID)
    setFrom(() => res.data.from)
    setTo(() => res.data.to)

    const resIsTrip = res.data.to.rki !== null

    setIsTrip(() => resIsTrip)

    let errors = []
    let rkiData = []

    try {
      rkiData = await APIService.getRKIData()
    } catch (error) {
      console.error(error)
      errors.push('Fehler bei der RKI-Schnittstelle')
      setLoading(false)
    }

    if (errors.length > 0) {
      setErrors(errors)
      setLoading(false)
      return;
    }

    const zips = await LocationService.checkZips(resIsTrip, res.data.from, res.data.to, zipData, BerlinCodes, rkiData, setFilteredFrom, setFilteredTo, setErrors)
    const filteredFrom = zips[0]
    const filteredTo = zips[1]

    const partnerInfosFrom = await APIService.getPartnerInfoById(res.data.from.address.placeID, filteredFrom[0]?.attributes.BL, filteredFrom[0]?.attributes.county)
    let combinedInfosFrom = []

    if (partnerInfosFrom.data != null) {
      for (const partnerInfo of partnerInfosFrom.data) {
        if (partnerInfo.active) {
          combinedInfosFrom.push(partnerInfo.messages)
        }
      }
      setInfoFrom(combinedInfosFrom)
    }

    if (resIsTrip) {
      const partnerInfosTo = await APIService.getPartnerInfoById(res.data.to.address.placeID, filteredTo[0]?.attributes.BL, filteredTo[0]?.attributes.county)
      let combinedInfosTo = []

      if (partnerInfosTo.data != null) {
        for (const partnerInfo of partnerInfosTo.data) {
          if (partnerInfo.active) {
            combinedInfosTo.push(partnerInfo.messages)
          }
        }
        setInfoTo(combinedInfosTo)
      }
    }
    setLoading(false)
  }

  useEffect(() => {
    getTripData()
  }, [])

  return (
    <div>
      <div className={styles.header}>
        <h1 className={styles.title}>
          Aktueller Reisestatus
        </h1>
        <span
          onClick={getTripData}
          className={[
            styles.refreshIcon,
            loading ? styles.loading : ''
          ].join(' ')}>
          <SyncRoundedIcon />
        </span>
      </div>

      <hr />

      <Legend trip={isTrip} filteredFrom={filteredFrom[0]} filteredTo={filteredTo[0]} />

      {!loading ? (
        <div className={styles.wrapper}>
          {filteredFrom?.length > 0 && (
            <div className={styles.result}>
              <ResultBox data={{ ...from, rki: filteredFrom[0]?.attributes }} />
            </div>
          )}
          {filteredTo?.length > 0 && (
            <>
              <div className={styles.arrowTo}>
                <ArrowForwardRoundedIcon />
              </div>
              <div className={styles.result}>
                <ResultBox data={{ ...to, rki: filteredTo[0]?.attributes }} />
              </div>
            </>
          )}
        </div>
      ) : <Loader variant='secondary' style={{ margin: '1.5rem 0', padding: '5rem 0', backgroundColor: '#f7f7f7', borderRadius: '5px' }} />}

      <span className={styles.copyright}>
        &copy; {new Date().getFullYear()} – Die Daten basieren auf den Fallzahlen des <a href="https://www.rki.de/" target="_blank" rel="noreferrer">RKI</a> und werden mithilfe der Schnittstellen von <a href="https://www.esri.de/" target="_blank" rel="noreferrer">ESRI</a> genutzt
      </span>

      {!loading && (
        <PartnerInfo
          isTrip={isTrip}
          from={from}
          to={to}
          infoFrom={infoFrom}
          infoTo={infoTo}
          filteredFrom={filteredFrom[0]}
          filteredTo={filteredTo[0]}
        />
      )}

      <h3>Einschränkungen &amp; Lockerungen</h3>
      <p>Diese Daten entnehmen wir dem <a href="https://tourismus-wegweiser.de/" target="_blank" rel="noreferrer">Infoangebot</a> des Kompetenzzentrums Tourismus des Bundes.</p>

      {!loading && (
        <TourismInfo filteredFrom={filteredFrom} filteredTo={filteredTo} isTrip={isTrip} />
      )}

      {/* <h3>Old Trip Data</h3>
      <div className={styles.wrapper}>
        {from && (
          <div className={styles.result}>
            <ResultBox data={from} />
          </div>
        )}
        {to?.rki && (
          <>
            <div className={styles.arrowTo}>
              <ArrowForwardRoundedIcon />
            </div>
            <div className={styles.result}>
              <ResultBox data={to} />
            </div>
          </>
        )}
      </div> */}

    </div>
  )
}
