import React from 'react'
import styles from './partnerinfo.module.scss'

export const PartnerInfo = ({ isTrip, from, to, infoFrom, infoTo, filteredFrom, filteredTo }) => {

  const fromCases = filteredFrom?.attributes.cases7_per_100k
  const toCases = filteredTo?.attributes.cases7_per_100k

  return (
    (infoFrom?.length > 0 || (infoTo?.length > 0 && isTrip)) ? (
      <div className={styles.wrapper}>
        <h3 className={styles.title}>Weitere Informationen</h3>

        {infoFrom.length > 0 && (
          <div className={styles.info}>
            {isTrip && (
              <h4 className={styles.infolocation}>
                {from.address.name.split(',')[0]}:
              </h4>
            )}
            {infoFrom.map(infos => (
              infos.map((info, i) => {
                if ((fromCases >= info.from) && (fromCases < info.to)) {
                  return (
                    <p className={styles.singleinfo} key={i}>
                      {info.message}
                    </p>
                  )
                }
                return null
              })
            ))}
          </div>
        )}

        {isTrip && infoTo.length > 0 && (
          <div className={styles.info}>
            <h4 className={styles.infolocation}>
              {to.address.name.split(',')[0]}:
                        </h4>
            {infoTo.map(infos => (
              infos.map((info, i) => {
                if ((toCases >= info.from) && (toCases < info.to)) {
                  return (
                    <p className={styles.singleinfo} key={i}>
                      {info.message}
                    </p>
                  )
                }
                return null
              })
            ))}
          </div>
        )}
      </div>
    ) : <></>
  )
}
