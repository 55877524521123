import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onUpdate: async registration => {
    console.log('New version available!');

    interface ServiceWorkerEvent extends Event {
      target: Partial<ServiceWorker> & EventTarget | null;
    }

    if (registration && registration.waiting) {
      await registration.unregister()
      registration.waiting.addEventListener("statechange", (event: ServiceWorkerEvent) => {
        if (event.target?.state === "activated") {
          window.location.reload()
        }
      });
      registration.waiting.postMessage({ type: "SKIP_WAITING" });
    }
  }
})

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();