import * as React from 'react';
import './App.scss';
import { Header } from './components/Header';
import { MainWrapper } from './components/MainWrapper';
import { Trip } from './components/Trip';

function App() {
  return (
    <>
      <Header />
      <MainWrapper>
        <Trip />
      </MainWrapper>
    </>
  );
}

export default App;
