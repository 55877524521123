import React, { useState, useEffect } from 'react'
import styles from './tourisminfo.module.scss'
import { Info } from './Info'
import axios from 'axios'
import { Loader } from '../Loader'

export const TourismInfo = ({ filteredFrom, filteredTo, isTrip }) => {
  const [tourismDataFrom, setTourismDataFrom] = useState([])
  const [tourismDataTo, setTourismDataTo] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const getTourismData = async () => {
      setLoading(true)
      const URL = 'https://widget.meinereise.org/api/restrictions'

      const config = {
        method: 'GET',
        mode: 'cors',
        url: URL
      }

      const res = await axios(config)
      const filteredTourismDataFrom = res.data.filter(
        (state) => state['Bundesland'] === filteredFrom[0]?.attributes.BL
      )
      const filteredTourismDataTo = res.data.filter(
        (state) => state['Bundesland'] === filteredTo[0]?.attributes.BL
      )
      setTourismDataFrom(filteredTourismDataFrom)
      setTourismDataTo(filteredTourismDataTo)

      setLoading(false)
    }

    getTourismData()
  }, [])

  return (
    <div className={styles.wrapper}>
      {loading ? (
        <div className={styles.loader}>
          <Loader variant="secondary" />
        </div>
      ) : (
        <>
          <Info data={tourismDataFrom} />
          {isTrip && <Info data={tourismDataTo} />}
        </>
      )}
    </div>
  )
}
