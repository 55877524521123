import React from 'react'
import styles from './resultbox.module.scss'

export const ResultBox = ({ data }) => {

  return (
    <div className={styles.box} style={{
      backgroundColor:
        data.rki.cases7_per_100k > 100 ? '#a5081e' :
          data.rki.cases7_per_100k > 50 ? '#d40035' :
            data.rki.cases7_per_100k > 35 ? '#da9e45' :
              '#4eb769'
    }}>
      <h3>{data.address.name.split(',')[0]}</h3>

      <table className={styles.table}>
        <tbody>
          <tr>
            <th scope="row">PLZ:</th>
            <td>{data.address.zip}</td>
          </tr>
          <tr>
            <th scope="row">Kreis:</th>
            <td>{data.rki.county}</td>
          </tr>
          <tr>
            <th scope="row">Bundesland:</th>
            <td>{data.rki.BL}</td>
          </tr>
          <tr>
            <th scope="row">Inzidenzwert:</th>
            <td><strong>{data.rki.cases7_per_100k.toFixed(2)}</strong></td>
          </tr>
          <tr>
            <th scope="row">Fälle insgesamt:</th>
            <td>{data.rki.cases}</td>
          </tr>
          <tr>
            <th scope="row">Letztes Update:</th>
            <td>{data.rki.last_update.split(',')[0]}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}
