import React from 'react'
import styles from './header.module.scss'

export const Header = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.logo}>
        <a href="https://www.meinereise.org/" target="_blank" rel="noreferrer">
          <img src="https://cdn.meinereise.org/img/logo.png" alt="MeineReise Logo" />
        </a>
      </div>
    </div>
  )
}
